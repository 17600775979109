import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function BuyNow() {
  const [paid, setPaid] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [amount, setAmount] = useState(6500);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponBox, setCBox] = useState(true);
  const [codeDiscount, setCDiscount] = useState(0);
  const [error, setErr] = useState("");
  // Function to load Razorpay script dynamically
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      document.body.appendChild(script);
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
    });
  };

  const handleCoupon = () => {
    if (coupon.length === 0) {
      setErr("Please enter coupon code");
    } else {
      const formData = new FormData();
      formData.append("API_KEY", "RecallX84014");
      formData.append("op", "couponCheck");
      formData.append("code", coupon);

      fetch("https://api.recallx.in/blog.php", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.error_code === 0) {
            setCDiscount(data.discount);
            setCBox(false);
            setAmount(amount - data.discount);
          } else {
            setErr("Invalid coupon code");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };
  // Function to initiate payment
  const handlePayment = async () => {
    if (name.length === 0) {
      setErr("Please enter your name");
    } else if (email.length === 0) {
      setErr("Please enter your email-id");
    } else if (phone.length === 0) {
      setErr("Please enter your phone number");
    } else {
      const isScriptLoaded = await loadRazorpayScript();

      if (!isScriptLoaded) {
        alert("Failed to load Razorpay SDK. Check your internet connection.");
        return;
      }

      // Call the backend (PHP) to create an order and get order_id
      try {
        const response = await fetch(
          "https://api.recallx.in/create-order.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ amount }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to create an order on the server.");
        }

        const data = await response.json();

        const options = {
          key: "rzp_live_86lr0ke6FF1iuh", // Replace with your Razorpay Key ID
          amount: data.amount, // Amount in paise (not rupees)
          currency: data.currency,
          name: "RecallX",
          description: "Buy Course",
          image: "https://recallx.in/assets/images/recallx-logo.png",
          order_id: data.orderId, // Order ID from backend (PHP)
          handler: function (response) {
            setPaymentId(response.razorpay_payment_id);
            setPaid(true);
            payAlert();
            // alert(
            //   `Payment Successful! Payment ID: ${response.razorpay_payment_id}`
            // );
            // You can call your backend to verify the payment response if needed.
          },
          prefill: {
            name: name,
            email: email,
            contact: phone, // User's contact number
          },
          notes: {
            referer_code: coupon,
          },
          theme: {
            color: "#0f0f88",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (error) {
        console.error("Error:", error);
        alert("Something went wrong with the payment.");
      }
    }
  };

  const payAlert = () => {
    const formData = new FormData();
    formData.append("API_KEY", "RecallX84014");
    formData.append("op", "purchesAlert");
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);

    fetch("https://api.recallx.in/blog.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.error_code === 0) {
          setCDiscount(data.discount);
          setCBox(false);
          setAmount(amount - data.discount);
        } else {
          setErr("Invalid coupon code");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Checkout | RecallX</title>
      </Helmet>
      {/* /.main-header */}
      <div
        className="stricky-header stricked-menu main-menu main-menu-with-bg"
        style={{ background: "#EFEFF3" }}
      ></div>
      {/* /.stricky-header */}
      {/* About Start */}

      {paid ? (
        <div
          className="container mt-5 d-flex justify-content-center"
          style={{ marginBottom: 100 }}
        >
          <div className="card text-center p-4" style={{ maxWidth: "400px" }}>
            <div className="card-body">
              <h4 className="card-title">Payment Successful!</h4>
              <p className="card-text text-muted">
                Thank you for your payment. Your transaction was completed
                successfully.
              </p>
              <p style={{ color: "#00C000" }}>
                <b>Your Payment ID : {paymentId}</b>
              </p>
              <p>
                <b>You will get your course access soon...</b>
              </p>
              <Link to="/course" className="eduact-btn eduact-btn-second">
                Need Any Help ? <br /> (+91 98798 14480)
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <section className="about-three mt-5">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-8 wow fadeInLeft order-2 order-md-1"
                data-wow-delay="100ms"
              >
                <div className="about-three__content">
                  {/* about content start*/}
                  <div className="section-title">
                    <h5 className="section-title__tagline">
                      Buy Course Now
                      <svg
                        className="arrow-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 55 13"
                      >
                        <g clipPath="url(#clip0_324_36194)">
                          <path d="M10.5406 6.49995L0.700562 12.1799V8.56995L4.29056 6.49995L0.700562 4.42995V0.819946L10.5406 6.49995Z" />
                          <path d="M25.1706 6.49995L15.3306 12.1799V8.56995L18.9206 6.49995L15.3306 4.42995V0.819946L25.1706 6.49995Z" />
                          <path d="M39.7906 6.49995L29.9506 12.1799V8.56995L33.5406 6.49995L29.9506 4.42995V0.819946L39.7906 6.49995Z" />
                          <path d="M54.4206 6.49995L44.5806 12.1799V8.56995L48.1706 6.49995L44.5806 4.42995V0.819946L54.4206 6.49995Z" />
                        </g>
                      </svg>
                    </h5>
                    <h2 className="section-title__title">
                      Master Speed Reading with Recall X!
                    </h2>
                  </div>
                  {/* section-title */}
                  <p className="about-three__content__text">
                    Imagine being able to read texts, reports, or study
                    materials for exams three times faster without facing any
                    delays. The best speed reading course accessible, Recall X,
                    is designed for all types of readers, including students,
                    continuous learners, and professionals. You'll learn
                    scientifically proven methods to increase your reading speed
                    and retention in just 26 days.
                  </p>
                  <p style={{ fontSize: 18, fontStyle: "italic" }}>
                    <span style={{ fontSize: 25, fontWeight: "bold" }}>"</span>
                    The faster you read, the more you learn — maximise your time
                    with Recall X!
                    <span style={{ fontSize: 25, fontWeight: "bold" }}>"</span>
                  </p>
                  <p className="about-three__content__text">
                    We are celebrating the launch of our course by offering
                    those who register a special discount. Don't miss this
                    chance to reduce costs on your path to being a faster,
                    smarter reader. Before it ends, enrol now to take advantage
                    of this exclusive deal! Spaces are filling up fast, so don’t
                    miss your chance to become a more efficient and informed
                    reader. Your journey to faster, smarter reading begins here!
                  </p>

                  {/* /.icon-box */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="about-three__author">
                        <div className="about-three__author__thumb">
                          <img
                            src="assets/images/nilay-doshi.png"
                            alt="eduact"
                          />
                        </div>
                        <p style={{ fontSize: 21, fontWeight: "bold" }}>
                          Mr. Nilay Doshi
                        </p>
                        <p className="about-three__author__text">
                          Your Coach , Founder
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="about-three__info">
                        <div className="about-three__info__icon">
                          <span className="icon-Call" />
                        </div>
                        <p className="about-three__info__text">
                          Need to Know More Details?
                        </p>
                        <h4 className="about-three__info__title">
                          <a href="tel:919879814480">+(91) 98798 14480</a>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="about-three__br" />
                </div>
                {/* about content end */}
              </div>
              <div
                className="col-xl-4 wow fadeInRight order-1 order-md-2 mb-4"
                data-wow-delay="100ms"
              >
                <div
                  style={{
                    padding: 25,
                    border: "solid 1px #5f60b2",
                    borderRadius: 20,
                  }}
                >
                  <h2 className="mb-3">Checkout</h2>
                  <br />
                  <div className="contact-one__input-box">
                    <input
                      type="text"
                      id="name"
                      placeholder="Your Name"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      autoComplete="on"
                    />
                  </div>

                  <div className="contact-one__input-box">
                    <input
                      type="email"
                      id="email"
                      placeholder="Email Address"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      autoComplete="on"
                    />
                  </div>

                  <div className="contact-one__input-box">
                    <input
                      type="text"
                      id="phone"
                      placeholder="Phone Number"
                      name="phone"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      autoComplete="on"
                    />
                  </div>

                  <table className="table" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Course</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>RecallX</td>
                        <td>13,000</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="table" style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Sub-Total</td>
                        <td style={{ fontWeight: "bold", textAlign: "right" }}>
                          13,000
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "bold" }}>Discount</td>
                        <td style={{ fontWeight: "bold", textAlign: "right" }}>
                          6,500
                        </td>
                      </tr>
                      {codeDiscount > 0 ? (
                        <tr>
                          <td style={{ fontWeight: "bold" }}>
                            Coupon Discount
                          </td>
                          <td
                            style={{ fontWeight: "bold", textAlign: "right" }}
                          >
                            {codeDiscount}
                          </td>
                        </tr>
                      ) : null}

                      <tr>
                        <td style={{ fontSize: 25, fontWeight: "bold" }}>
                          Total
                        </td>
                        <td
                          style={{
                            fontSize: 25,
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          {amount}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {couponBox ? (
                    <div className="row">
                      <div className="col-6">
                        <div className="contact-one__input-box">
                          <input
                            type="text"
                            id="coupon"
                            placeholder="Coupon Code"
                            name="coupon"
                            value={coupon}
                            onChange={(e) => {
                              setCoupon(e.target.value);
                            }}
                            autoComplete="on"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <button
                          className="eduact-btn eduact-btn-second"
                          style={{
                            width: "100%",
                            backgroundColor: "#f57005",
                          }}
                          onClick={handleCoupon}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="row"
                      style={{
                        borderRadius: 15,
                        border: "dashed 1px #04aa6d",
                        padding: 18,
                        color: "#04aa6d",
                        fontWeight: "bold",
                        margin: 0,
                        marginBottom: 8,
                      }}
                    >
                      Coupon code applied
                    </div>
                  )}

                  <button
                    className="eduact-btn eduact-btn-second"
                    style={{ width: "100%", backgroundColor: "#0f118c" }}
                    onClick={handlePayment}
                  >
                    Pay Now
                  </button>
                  {error.length > 0 ? (
                    <div
                      style={{
                        backgroundColor: "#FF5733",
                        width: "100%",
                        marginBottom: 8,
                        marginTop: 15,
                        padding: 8,
                        color: "#FFF",
                        fontSize: 18,
                        borderRadius: 8,
                      }}
                    >
                      {error}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="video-one" style={{ marginTop: -60 }}>
        <div className="container">
          <div
            className="video-one__bg"
            style={{
              backgroundImage: "url(assets/images/backgrounds/video-bg-1.jpg)",
            }}
          >
            <div
              className="video-one__shape"
              style={{
                backgroundImage: "url(assets/images/shapes/video-shape-1.png)",
              }}
            />
            <div
              className="video-one__shape2"
              style={{
                backgroundImage: "url(assets/images/shapes/video-shape-2.png)",
              }}
            />
            <div className="row">
              <div
                className="col-lg-6 col-md-7 wow fadeInLeft"
                data-wow-delay="200ms"
              >
                <h3 className="video-one__title">
                  Watch the full introduction of the course
                </h3>
                <span style={{ fontSize: 65, color: "#f57005" }}>{">>>"}</span>
              </div>
              <div
                className="col-lg-6 col-md-5 wow fadeInRight"
                data-wow-delay="200ms"
              >
                <div className="video-one__btn">
                  <img src="assets/images/33.jpg" alt="eduact" />
                  <Link to="/videos" className="video-popup">
                    <span className="icon-play" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
